import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

const ActiveUserModel = ({ open, handleClose, user, handleActiveUser, editUser }) => {

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => handleClose(false)}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                    Candidate Reactivation
                </Typography>
                <Typography variant="body1" component="p">
                {`The profile of ${user.first_name} ${user?.last_name} is being reactivated in the system, moving from "Archive" to "Active" status.
                Please confirm if you want to proceed with this action.`}
                </Typography>
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => handleClose(false)}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => {return editUser ? handleActiveUser('yes', 'yes') :handleActiveUser(user?.id, 'active', 'yes', 'yes')}}
                  >
                    {" "}
                    Skip
                  </Button>                  
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => {return editUser ? handleActiveUser('yes') :handleActiveUser(user?.id, 'active', 'yes')}}
                  >
                    {" "}
                    Confirm
                  </Button>
                
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ActiveUserModel;
