import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  RiDeleteBinLine,
  RiEyeLine,
  RiPencilLine,
  RiRotateLockLine,
} from "react-icons/ri";
import {
  Box,
  Pagination,
  Tab,
  Tabs,
  Typography,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteUserModal from "../../common/deletusermodal";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsersByType } from "../../store/slices/userSlice";
import { editUserByIdAdmin } from "../../store/slices/editUserByAdminSlice";
import { successToast } from "../../responses/successToast";
import { usersPerPage } from "../../utils/constant";
import "./user.scss";
import ChangepasswordModal from "./changeuserpasswordmodal";
import getTokenFromLocalStorage from "../../utils/token";
import { CustomTablePaginationToolbar } from "../../common/Pagination";
import ActiveUserModel from "../../common/ActiveUserModel";

const Userlist = ({
  searchUser,
  onValueChange,
  onPageChange,
  currentPage,
  rowsPerPage,
  setRowsPerPage,
  currentStatus,
  company,
  value,
}) => {
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      currentPage: 1,
      rowsPerPage: 10,
      search: "",
      company: "1",
    };
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getTokenFromLocalStorage();
  const [open, setOpen] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectUserName, setSelectedUserName] = useState("");
  const [loadingInternal, setLoadingInternal] = useState(true);
  const [loadingExternal, setLoadingExternal] = useState(true);
  const [loadingArchive, setLoadingArchive] = useState(true);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeModelOpen, setActiveModelOpen] = useState(false);
  const [activeUser, setActiveUser] = useState({});
  const [userStatusLoading, setUserStatusLoading] = useState({});

  const userType = value === 0 ? "internal" : value === 1 ? "external" : "";

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const handlePasswordOpen = (userId, username) => {
    setSelectedUserId(userId);
    setSelectedUserName(username);
    setPasswordModalOpen(true);
  };
  const closePasswordModal = () => setPasswordModalOpen(false);

  const handleOpen = (userId) => {
    setSelectedUserId(userId);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleChange = (event, newValue) => {
    onValueChange(newValue);
  };

  const handleCopyClick = (textToCopy, msg) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      successToast(`${msg} copied succesfully!`);
    });
  };

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage)
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange(1)
  };
  const users = useSelector((state) => state.users.users);
  const internalUsers = users?.data?.filter(
    (user) => value === 0 && user.type === "internal"
  );
  const externalUsers = users?.data?.filter(
    (user) => value === 1 && user.type === "external"
  );
  const archievedUsers = users?.data?.filter(
    (user) => user.status === "archive"
  );

  const handleStatusChange = async (id, newStatus, confirm='', skip='') => {

    const user = users?.data?.find((user) => user.id === id);
    
    if(newStatus === 'active' && confirm !== 'yes'){
      setActiveModelOpen(true)
      setActiveUser(user)
      return
    }
    setActiveModelOpen(false)
    
    let newActiveUsers = [...activeUsers];
    setUserStatusLoading((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    if (user) {
      const userIndex = internalUsers.indexOf(user);
      newActiveUsers[userIndex] = newStatus;
      setActiveUsers(newActiveUsers);
      const userDataWithoutStatus = { ...user };
      delete userDataWithoutStatus.status;
      try {
        const payload = {
          status: newStatus,
          ...userDataWithoutStatus,
        }
        if(newStatus === 'active'){
          payload.skip = skip === 'yes' ? 'yes' : 'no'
        }
        const response = await dispatch(
          editUserByIdAdmin(id, token, payload)
        );
        if (response.success) {
          successToast(response.message);
        }
      } catch (error) {
        console.error("Error updating user status:", error);
      }
      dispatch(
        fetchUsersByType({
          userType,
          searchQuery: "",
          page: currentPage,
          status: value === 0 || value === 1 ? "active" : "archive",
          rowsPerPage,
          company,
        })
      );
      setTimeout(() => {
        setUserStatusLoading((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }, 1500);
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, usersPerPage, currentPage, ...other } =
      props;

    return (
      <div
        role="tabpanel"
        
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <Typography variant="body1" component="span" className="user-name">
          {role}
        </Typography>{" "}
      </Box>
    );
  };

  useEffect(() => {
    setLoadingInternal(true);
    setLoadingExternal(true);
    setLoadingArchive(true);

    const fetchData = async () => {
      try {
        await dispatch(
          fetchUsersByType({
            userType,
            searchQuery: searchUser,
            page:currentPage,
            status: value === 0 || value === 1 ? "active" : "archive",
            rowsPerPage,
            company,
          })
        );

        if (value === 0) {
          setLoadingInternal(false);
        } else if (value === 1) {
          setLoadingExternal(false);
        } else {
          setLoadingArchive(false);
        }
      } catch (error) {
        if (value === 0) {
          setLoadingInternal(false);
        } else if (value === 1) {
          setLoadingExternal(false);
        } else {
          setLoadingArchive(false);
        }
        console.error("Error fetching users:", error);
      }
    };

    fetchData();
  }, [
    dispatch,
    value,
    currentPage,
    userType,
    rowsPerPage,
    searchUser,
    company,
  ]);

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-area"
          >
            <Tab label={tabLabel("Internal")} {...a11yProps(0)} />
            <Tab label={tabLabel("External")} {...a11yProps(1)} />
            <Tab label={tabLabel("Archived")} {...a11yProps(2)} />
          </Tabs>
        </Box>
        {value === 0 ? (
          <>
            {loadingInternal ? (
              <TableContainer >
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        Emp Id
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Company</TableCell>
                      <TableCell align="left">Designation</TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        Accessible Applications
                      </TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : internalUsers?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={0}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                className="user-listing-tables"
                key={`user-list-${0}`}
              >
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Emp Id
                        </TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Company</TableCell>
                        <TableCell align="left">Designation</TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          Accessible Applications
                        </TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {internalUsers?.map((user, index) => (
                        <TableRow key={user.id}>
                          {userStatusLoading[user.id] ? (
                            <TableCell colSpan={8}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                              />
                            </TableCell>
                          ) : (
                            <>
                              <TableCell component="th" scope="row">
                                {user?.employee_number}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                <Typography variant="body1" component="span">
                                  {capitalizeFirstLetter(user?.first_name)}{" "}
                                  {capitalizeFirstLetter(user?.last_name)}{" "}
                                </Typography>{" "}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {user?.company?.company_name ? (
                                  <Tooltip
                                    title={
                                      <div
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {`Click to Copy : ${user?.company?.company_name}`}
                                      </div>
                                    }
                                    arrow
                                  >
                                    <span
                                      onClick={() => {
                                        handleCopyClick(
                                          user?.company?.company_name,
                                          "Company Name"
                                        );
                                      }}
                                    >
                                      {user?.company?.company_name.length < 14
                                        ? user?.company?.company_name
                                        : user?.company?.company_name.slice(
                                            0,
                                            17
                                          ) + "..."}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {user?.designation?.designation_name}
                              </TableCell>
                              <TableCell align="left">
                                <Box className="app-list">
                                  <Box
                                    variant="div"
                                    component="div"
                                    className="tag-wrap"
                                  >
                                    {user?.application_details.map(
                                      (appDetail, index) =>
                                        index < 1 && (
                                          <tag key={appDetail.app_id}>
                                            {appDetail?.application_name}
                                          </tag>
                                        )
                                    )}
                                  </Box>
                                  {user?.application_details?.length > 1 && (
                                    <Typography
                                      variant="body1"
                                      component="p"
                                      className="plus-users"
                                    >
                                      +
                                      <Typography
                                        variant="body1"
                                        component="span"
                                        className="plus-users"
                                      >
                                        {user?.application_details?.length - 1}
                                      </Typography>{" "}
                                      more
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box sx={{ display: "flex" }}>
                                  <Box className="mask-box">
                                    <Box
                                      className="mask"
                                      style={{
                                        transform: `translateX(${
                                          user.status === "active" ? 0 : "100%"
                                        })`,
                                        transition:
                                          "transform 0.3s ease-in-out",
                                      }}
                                    />
                                    <>
                                      <Button
                                        disableRipple
                                        variant="text"
                                        style={{
                                          backgroundColor:
                                            activeUsers[user.status] ===
                                            "active"
                                              ? "var(--light-green)"
                                              : "transparent",
                                        }}
                                        onClick={() =>
                                          handleStatusChange(user.id, "active")
                                        }
                                      >
                                        Active
                                      </Button>
                                      <Button
                                        disableRipple
                                        variant="text"
                                        style={{
                                          backgroundColor:
                                            activeUsers[user.status] ===
                                            "archive"
                                              ? "var(--light-green)"
                                              : "transparent",
                                        }}
                                        onClick={() =>
                                          handleStatusChange(user.id, "archive")
                                        }
                                      >
                                        Archive
                                      </Button>
                                    </>
                                    {/* )} */}
                                  </Box>
                                </Box>
                              </TableCell>

                              <TableCell align="left">
                                <Box className="action-btn-wrap">
                                  <Button
                                    variant="outlined"
                                    className="action-btn view-btn"
                                    startIcon={<RiEyeLine />}
                                    disableRipple
                                    onClick={() =>
                                      navigate(`/users/${user.id}/view`, {
                                        state: {
                                          status: value,
                                         
                                          search: searchUser,
                                          rowsPerPage: rowsPerPage,
                                          company: company,
                                          currentPage: currentPage,
                                        },
                                      })
                                    }
                                    title="View"
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    disableRipple
                                    onClick={() =>
                                      navigate(`/users/${user.id}/edit`, {
                                        state: {
                                          status: value,
                                
                                          search: searchUser,
                                          rowsPerPage: rowsPerPage,
                                          company: company,
                                          currentPage: currentPage,
                                        },
                                      })
                                    }
                                    title="Edit"
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn password-btn"
                                    startIcon={<RiRotateLockLine />}
                                    disableRipple
                                    onClick={() => {
                                      handlePasswordOpen(
                                        user.id,
                                        `${user.first_name} ${user.last_name}`
                                      );
                                    }}
                                    title="Change Password"
                                  ></Button>
                                </Box>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                 
                </TableContainer>
                <div style={{ border: "0px solid red" }}>
                  <CustomTablePaginationToolbar
                    count={users?.total || 0}
                    page={currentPage}
                    rowsPerPage={+rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </CustomTabPanel>
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No users found
              </Typography>
            )}
          </>
        ) : value === 1 ? (
          <>
            {loadingExternal ? (
              <TableContainer component={Table}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Emp Id</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Designation</TableCell>
                      <TableCell align="left">
                        Accessible Applications
                      </TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : externalUsers?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={1}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                key={`user-list-${1}`}
                className="user-listing-tables"
              >
                <TableContainer component={Table}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>{" "}
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">
                          Accessible Applications
                        </TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {externalUsers?.map((user) => (
                        <TableRow key={user.id}>
                          {userStatusLoading[user.id] ? (
                            <TableCell colSpan={8}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                              />
                            </TableCell>
                          ) : (
                            <>
                              <TableCell align="left">
                                <Typography variant="body1" component="span">
                                  {" "}
                                  {capitalizeFirstLetter(user?.first_name)}{" "}
                                  {capitalizeFirstLetter(user?.last_name)}{" "}
                                </Typography>
                              </TableCell>

                              <TableCell align="left">{user?.email}</TableCell>
                              <TableCell align="left">
                                <Box className="app-list">
                                  <Box
                                    variant="div"
                                    component="div"
                                    className="tag-wrap"
                                  >
                                    {user?.application_details?.map(
                                      (appDetail, index) =>
                                        index < 1 && (
                                          <tag key={appDetail?.app_id}>
                                            {appDetail?.application_name}
                                          </tag>
                                        )
                                    )}
                                  </Box>
                                  {user?.application_details?.length > 1 && (
                                    <Typography variant="body1" component="p">
                                      +{" "}
                                      <Typography
                                        variant="body1"
                                        component="span"
                                      >
                                        {user?.application_details?.length - 1}
                                      </Typography>{" "}
                                      more
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box sx={{ display: "flex" }}>
                                  <Box className="mask-box">
                                    <Box
                                      className="mask"
                                      style={{
                                        transform: `translateX(${
                                          user.status === "active" ? 0 : "100%"
                                        })`,
                                        transition:
                                          "transform 0.3s ease-in-out",
                                      }}
                                    />
                                    <Button
                                      disableRipple
                                      variant="text"
                                      style={{
                                        backgroundColor:
                                          activeUsers[user.status] === "active"
                                            ? "var(--light-green)"
                                            : "transparent",
                                      }}
                                      onClick={() =>
                                        handleStatusChange(user.id, "active")
                                      }
                                    >
                                      Active
                                    </Button>
                                    <Button
                                      disableRipple
                                      variant="text"
                                      style={{
                                        backgroundColor:
                                          activeUsers[user.status] === "archive"
                                            ? "var(--light-green)"
                                            : "transparent",
                                      }}
                                      onClick={() =>
                                        handleStatusChange(user.id, "archive")
                                      }
                                    >
                                      Archive
                                    </Button>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box className="action-btn-wrap">
                                  <Button
                                    variant="outlined"
                                    className="action-btn view-btn"
                                    startIcon={<RiEyeLine />}
                                    disableRipple
                                    onClick={() =>
                                      navigate(`/users/${user.id}/view`, {
                                        state: {
                                          status: value,
                                        
                                          search: searchUser,
                                          rowsPerPage: rowsPerPage,
                                          company: company,
                                          currentPage: currentPage,
                                        },
                                      })
                                    }
                                    title="View"
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    disableRipple
                                    onClick={() =>
                                      navigate(`/users/${user.id}/edit`, {
                                        state: {
                                          status: value,
                        
                                          search: searchUser,
                                          rowsPerPage: rowsPerPage,
                                          company: company,
                                          currentPage: currentPage,
                                        },
                                      })
                                    }
                                    title="Edit"
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn password-btn"
                                    startIcon={<RiRotateLockLine />}
                                    disableRipple
                                    onClick={() => {
                                      handlePasswordOpen(
                                        user.id,
                                        `${user.first_name} ${user.last_name}`
                                      );
                                    }}
                                    title="Change Password"
                                  ></Button>
                                </Box>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                 
                </TableContainer>
                <div style={{ border: "0px solid red" }}>
                  <CustomTablePaginationToolbar
                    count={users?.total || 0}
                    page={currentPage}
                    rowsPerPage={+rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </CustomTabPanel>
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No users found
              </Typography>
            )}
          </>
        ) : (
          <>
            {loadingArchive ? (
              <TableContainer component={Table}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Emp Id</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Company</TableCell>
                      <TableCell align="left">Designation</TableCell>
                      <TableCell align="left">
                        Accessible Applications
                      </TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : archievedUsers?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={2}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                className="user-listing-tables"
                key={`user-list-${0}`}
              >
                <TableContainer component={Table}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Company</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">
                          Accessible Applications
                        </TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {archievedUsers?.map((user, index) => (
                        <TableRow key={user.id}>
                          {userStatusLoading[user.id] ? (
                            <TableCell colSpan={8}>
                              <Skeleton
                                variant="text"
                                width="100%"
                                height={40}
                              />
                            </TableCell>
                          ) : (
                            <>
                              <TableCell align="left">
                                <Typography variant="body1" component="span">
                                  {capitalizeFirstLetter(user?.first_name)}{" "}
                                  {capitalizeFirstLetter(user?.last_name)}{" "}
                                </Typography>{" "}
                              </TableCell>
                              <TableCell align="left">
                                <span>
                                  {user?.company?.company_name ? (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {`Click to Copy : ${user?.company?.company_name}`}
                                        </div>
                                      }
                                      arrow
                                    >
                                      <span
                                        onClick={() => {
                                          handleCopyClick(
                                            user?.company?.company_name,
                                            "Company Name"
                                          );
                                        }}
                                      >
                                        {user?.company?.company_name.length < 14
                                          ? user?.company?.company_name
                                          : user?.company?.company_name.slice(
                                              0,
                                              17
                                            ) + "..."}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </TableCell>
                              <TableCell align="left">{user?.email}</TableCell>
                              <TableCell align="left">
                                <Box className="app-list">
                                  <Box
                                    variant="div"
                                    component="div"
                                    className="tag-wrap"
                                  >
                                    {user?.application_details?.map(
                                      (appDetail, index) =>
                                        index < 1 && (
                                          <tag key={appDetail.app_id}>
                                            {appDetail?.application_name}
                                          </tag>
                                        )
                                    )}
                                  </Box>
                                  {user?.application_details?.length > 1 && (
                                    <Typography
                                      variant="body1"
                                      component="p"
                                      className="plus-users"
                                    >
                                      +
                                      <Typography
                                        variant="body1"
                                        component="span"
                                        className="plus-users"
                                      >
                                        {user?.application_details?.length - 1}
                                      </Typography>{" "}
                                      more
                                    </Typography>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box sx={{ display: "flex" }}>
                                  <Box className="mask-box">
                                    <Box
                                      className="mask"
                                      style={{
                                        transform: `translateX(${
                                          user.status === "active" ? 0 : "100%"
                                        })`,
                                        transition:
                                          "transform 0.3s ease-in-out",
                                      }}
                                    />
                                    <Button
                                      disableRipple
                                      variant="text"
                                      style={{
                                        backgroundColor:
                                          activeUsers[user.status] === "active"
                                            ? "var(--light-green)"
                                            : "transparent",
                                      }}
                                      onClick={() =>
                                        handleStatusChange(user.id, "active")
                                      }
                                    >
                                      Active
                                    </Button>
                                    <Button
                                      disableRipple
                                      variant="text"
                                      style={{
                                        backgroundColor:
                                          activeUsers[user.status] === "archive"
                                            ? "var(--light-green)"
                                            : "transparent",
                                      }}
                                      onClick={() =>
                                        handleStatusChange(user.id, "archive")
                                      }
                                    >
                                      Archive
                                    </Button>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <Box className="action-btn-wrap">
                                  <Button
                                    variant="outlined"
                                    className="action-btn view-btn"
                                    startIcon={<RiEyeLine />}
                                    disableRipple
                                    onClick={() =>
                                      navigate(`/users/${user.id}/view`, {
                                        state: {
                                          status: value,
                              
                                          search: searchUser,
                                          rowsPerPage: rowsPerPage,
                                          company: company,
                                          currentPage: currentPage,
                                        },
                                      })
                                    }
                                    title="View"
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    disableRipple
                                    onClick={() =>
                                      navigate(`/users/${user.id}/edit`, {
                                        state: {
                                          status: value,
                               
                                          search: searchUser,
                                          rowsPerPage: rowsPerPage,
                                          company: company,
                                          currentPage: currentPage,
                                        },
                                      })
                                    }
                                    title="Edit"
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn password-btn"
                                    startIcon={<RiRotateLockLine />}
                                    disableRipple
                                    onClick={() => {
                                      handlePasswordOpen(
                                        user.id,
                                        `${user.first_name} ${user.last_name}`
                                      );
                                    }}
                                    title="Change Password"
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn dlt-btn"
                                    startIcon={<RiDeleteBinLine />}
                                    disableRipple
                                    onClick={() => handleOpen(user.id)}
                                    title="Delete"
                                  ></Button>
                                </Box>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  
                </TableContainer>
                <div style={{ border: "0px solid red" }}>
                  <CustomTablePaginationToolbar
                    count={users?.total || 0}
                    page={currentPage}
                    rowsPerPage={+rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </CustomTabPanel>
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No users found
              </Typography>
            )}
          </>
        )}
      </Box>
      <ChangepasswordModal
        open={passwordModalOpen}
        handleClose={closePasswordModal}
        selectedUserId={selectedUserId}
        username={selectUserName}
      />
      <DeleteUserModal
        open={open}
        handleClose={handleClose}
        selectedUserId={selectedUserId}
        value={value}
      />
      <ActiveUserModel
        open={activeModelOpen}
        handleClose={setActiveModelOpen}
        user={activeUser}
        handleActiveUser={handleStatusChange}
      />
    </>
  );
};

export default Userlist;
